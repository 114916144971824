import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { graphql } from 'gatsby';
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { IconContext } from 'react-icons';
import { FiLinkedin, FiTwitter } from 'react-icons/fi';
import LinkButton from '../../components/buttons/link-button';
import SEO from '../../components/seo';

function MichaelHawton({ data }) {
  const seoTitle = data.pageSeo.edges[0].node.pageName;
  const seoDescription =
    data.pageSeo.edges[0].node.description.internal.content;
  return (
    <>
      <SEO title={seoTitle} description={seoDescription} />
      <Flex
        justifyContent="space-between"
        w="100%"
        mt={16}
        mb={16}
        flexDir={{ base: 'column', lg: 'row' }}
      >
        <Flex flexDir="column" w="100%" flexGrow={1} order={{ base: 2, lg: 1 }}>
          <Flex
            flexDir="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            w="100%"
            flexGrow={1}
          >
            <Text
              textTransform="uppercase"
              letterSpacing=".2rem"
              fontWeight="semibold"
              color="primary.500"
              fontSize=".9rem"
            >
              Parentshop founder
            </Text>
            <Heading as="h1" mt={3}>
              {data.header.heading}
            </Heading>
            <Text fontSize="1.3rem" fontWeight="400" color="gray.600" mt={3}>
              MAPS, B.A., Grad. Dip. (App. Psych.), Dip. (Teaching), Grad. Cert.
              (Bus. Admin.)
            </Text>
            <Flex color="primary.500" mt={4}>
              <Box mr={4}>
                <IconContext.Provider value={{ size: '1.2rem' }}>
                  <Box>
                    <FiLinkedin
                      onClick={() =>
                        window.open(
                          'https://au.linkedin.com/in/michael-hawton-46a8b648',
                          '_blank'
                        )
                      }
                    />
                  </Box>
                </IconContext.Provider>
              </Box>
              <Box>
                <IconContext.Provider value={{ size: '1.2rem' }}>
                  <Box>
                    <FiTwitter
                      onClick={() =>
                        window.open(
                          'https://twitter.com/michaelhawton',
                          '_blank'
                        )
                      }
                    />
                  </Box>
                </IconContext.Provider>
              </Box>
            </Flex>
            <Text mt={8} color="gray.800">
              {data.header.introParagraph.internal.content}
            </Text>
          </Flex>
          <Flex
            mt={8}
            py={{ base: 4, md: 6 }}
            px={{ base: 4, md: 6 }}
            borderRadius="lg"
            bgColor="secondary.100"
            w="100%"
            justify="center"
            align="center"
          >
            <Text
              color="secondary.500"
              fontWeight="semibold"
              textAlign="left"
              w="100%"
            >
              For interviews and media requests call 1300 738 278 or{' '}
              <LinkButton text="contact us" link="contact" withIcon={false} />.{' '}
              <LinkButton
                text="See recent media here"
                link="about/media"
                withIcon={false}
              />
            </Text>
          </Flex>
        </Flex>
        <Box
          overflow="hidden"
          boxShadow="lg"
          borderRadius="lg"
          w={{ base: '100%', lg: '70%' }}
          ml={{ base: 0, lg: 16 }}
          order={{ base: 1, lg: 2 }}
          maxH={{ base: '20rem', lg: '100%' }}
          mb={{ base: 10, lg: 0 }}
        >
          <Img
            image={getImage(data.header.headerImage.gatsbyImage)}
            alt={data.header.headerImage.title}
          />
        </Box>
      </Flex>
    </>
  );
}

export default MichaelHawton;

export const query = graphql`
  query {
    pageSeo: allContentfulPageSeo(
      filter: { pageName: { eq: "about-michael-hawton" } }
    ) {
      edges {
        node {
          pageName
          description {
            internal {
              content
            }
          }
        }
      }
    }
    header: contentfulEntry(contentful_id: { eq: "6Xwfjo00HOScbQK2BU83m9" }) {
      ... on ContentfulPageHeader {
        id
        heading
        introParagraph {
          internal {
            content
          }
        }
        headerImage {
          title
          gatsbyImage(width: 1920)
        }
      }
    }
  }
`;
